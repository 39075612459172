img {
    display: flex;
    width: auto;
    height: 246px;
}

.gameUI {
    display: flex;
    justify-content: center;

    .turn {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 950px) {
    .gameUI {
        flex-direction: column;
    }
}