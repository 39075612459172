.App {
    text-align: center;
}

#App {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

html, body {
    height: 100%;
    background: linear-gradient(180deg, rgba(38, 134, 210, 1) 0%, rgba(197, 236, 240, 1) 100%);
    background-size: cover;
}