.button-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .battleship-img {
        width: auto;
        height: 246px;
    }

    .join-button {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
