.wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 10px;
    padding-right: 35px;
    padding-bottom: 35px;
    border-radius: 25px;
}

.gameboard {
    display: flex;
    justify-content: center; 
}

.rad {
    display: inline;

    .square {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

button {
    width: 100%;
    height: 100%;
    color: white;
    border: 1px solid rgb(192, 192, 192);
    opacity: 0.7;
    background-color: blue;
}

button:hover {
    opacity: 1;
    transition: 0.1s;
}

.water {
    background-color: yellow;
}

.alert {
    width: 90%;
    text-align: center;
}

.opponent {
    margin-top: 7%;
    padding-top: 10px;
    padding-right: 35px;
    padding-bottom: 35px;
    border-radius: 25px;
}

.active {
    background-color: green;
}
  
.hit {
    border: 4px solid red;
}
  
.miss {
    background-color: red;
}


@media screen and (max-width: 950px) {
    .opponent {
        margin-bottom: 100px;
    }
}